#wrapper #footer{background:url(../images/imgd119series/navigation_bg.png) #15669c;color:#FFF}
div#wrapper div#header{background:#15669c url(../images/imgd119series/navigation_bg.png);color:#FFF}
nav#nav-principal ul li:hover > a, nav#nav-principal ul li.actif > a{color:#15669c}
div#wrapper nav#nav-static-principal ul li a{color:#333;border-bottom:1px solid #15669c}
a.addmsglo{color:#FFF;background:#15669c;border:1px solid #ccc}
#form1 input.button{color:#FFF;background:#15669c;border:1px solid #ccc}
h2:first-letter, .h2:first-letter,div#wrapper nav#nav-static-principal ul h2:first-letter, div#wrapper nav#nav-static-principal ul .h2:first-letter,div#wrapper nav#nav-static-principal ul li a:hover,ul.menugalerie li a:hover,.galerie a:hover,#form1 p:first-letter{color:#15669c;}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {background-color: #15669c; border-color: #15669c;}.pagination > li > a, .pagination > li > span{color:#15669c;}
.produitsaccueil:hover,.produits:hover,.galerie:hover,#form1 input:hover,#form1 textarea:hover,input:hover{border:1px solid #15669c}
.button,#paiementchoix .button{color:#FFF;background:#15669c;border:1px solid #ccc}
.produit_etiquette:after{border-color:rgb(17,82,125)rgba(65,129,217,0) rgba(65,129,217,0)}
.produit_etiquette{background:rgb(17,82,125)}
.produit_etiquette2{background:rgb(17,82,125)}
.produit_etiquette2:after{border-color:rgb(160,8,102)rgb(8,58,89)rgba(0,0,0,0) rgba(0,0,0,0)}

/** Flux Panier **/

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: #15669c;
  border-color: #15669c;
}

.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: #15669c;
  color: #15669c;

  a, span {
    color: #15669c;
  }
}